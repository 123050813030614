<template>
  <div>
    <ModalAddBundle
      v-if="showAddBundle"
      :showModal="showAddBundle"
      :onClickCancel="hideModal"
    />

    <ModalDeleteBundle
      v-if="showDeleteBundle"
      :showModal="showDeleteBundle"
      :onClickCancel="hideModal"
    />

    <ModalEditBundle
      v-if="showEditBundle"
      :showModal="showEditBundle"
      :onClickCancel="hideModal"
    />

    <ui-page-hero
      id="id_title_channel"
      :title="'Functionality bundles'"
    ></ui-page-hero>

    <div
      class="actionBar has-background-white-ter has-padding has-margin-bottom"
    >
      <div class="level">
        <div class="level-left"></div>
        <div class="level-right">
          <a @click="setShowAddBundle" class="is-small">
            <span class="icon">
              <font-awesome-icon :icon="['fas', 'plus']" />
            </span>
            <span>Create bundle</span>
          </a>
        </div>
      </div>
    </div>

    <table class="table is-fullwidth is-striped">
      <thead>
        <tr>
          <th>Name</th>
          <th class="has-text-right">Functionalities</th>
          <!-- <th class="has-text-right">Is add on</th> -->
          <th width="75">&nbsp;</th>
        </tr>
      </thead>
      <transition-group
        name="staggered-fade"
        v-bind:css="false"
        v-on:before-enter="beforeEnter"
        v-on:enter="enter"
        tag="tbody"
      >
        <tr
          v-for="(bundle, index) in bundles"
          :key="'tr_' + index"
          :data-index="index"
        >
          <td>{{ bundle.Name }}</td>
          <td class="has-text-right">{{ bundle.Functionalities.length }}</td>
          <!-- <td class="has-text-right">
            <span class="icon">
              <font-awesome-icon
                v-if="bundle.IsAddOn"
                :icon="['fas', 'check']"
                class="has-text-success"
              />
              <font-awesome-icon
                v-if="!bundle.IsAddOn"
                :icon="['fas', 'times']"
                class="has-text-danger"
              />
            </span>
          </td> -->
          <td width="100" class="has-text-right">
            <div class="field is-grouped has-text-right">
              <p class="control">
                <a @click="setShowEditBundle(bundle)" class="is-small">
                  <span class="icon">
                    <font-awesome-icon :icon="['fas', 'edit']" />
                  </span>
                </a>
              </p>
              <p class="control">
                <span class="has-text-danger">
                  <a
                    @click="setShowDeleteBundle(bundle)"
                    class="has-text-danger"
                  >
                    <span class="icon">
                      <font-awesome-icon :icon="['fas', 'trash-alt']" />
                    </span>
                  </a>
                </span>
              </p>
            </div>
          </td>
        </tr>
      </transition-group>
    </table>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import staggeredAnimation from '@/mixins/staggeredAnimation'

export default {
  components: {
    ModalAddBundle: () => import('@/components/Bundles/ModalAddBundle'),
    ModalDeleteBundle: () => import('@/components/Bundles/ModalDeleteBundle'),
    ModalEditBundle: () => import('@/components/Bundles/ModalEditBundle'),
  },

  mixins: [staggeredAnimation],

  props: {},

  data() {
    return {
      channelId: Number(this.$route.params.channelId),
      showAddBundle: false,
      showDeleteBundle: false,
      showEditBundle: false,
    }
  },

  computed: {
    ...mapState('bundleStore', ['bundles']),
    ...mapState('functionalityStore', ['categories', 'functionalities']),
  },

  created() {
    if (this.bundles.length === 0) {
      this.getAllBundles(this.channelId)
    }

    if (this.categories.length === 0) {
      this.getAllCategories()
    }

    if (this.functionalities.length === 0) {
      this.getAllFunctionalities()
    }
  },

  beforeDestroy() {
    this.setBundle(null)
  },

  methods: {
    ...mapActions('bundleStore', ['getAllBundles']),
    ...mapMutations('bundleStore', ['setBundle']),
    ...mapActions('functionalityStore', [
      'getAllCategories',
      'getAllFunctionalities',
    ]),

    setShowDeleteBundle(bundle) {
      this.setBundle(bundle)
      this.showDeleteBundle = true
    },

    setShowAddBundle() {
      this.showAddBundle = true
    },

    setShowEditBundle(bundle) {
      this.setBundle(bundle)
      this.showEditBundle = true
    },

    hideModal() {
      this.showAddBundle = false
      this.showDeleteBundle = false
      this.showEditBundle = false

      this.setBundle(null)
    },
  },
}
</script>

<style></style>
